import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { QrCodePix } from 'qrcode-pix';
const qrCodePix = QrCodePix({
    version: '01',
    key: '+5584994751991', //or any PIX key
    name: 'Edgley Rodrigues de Souza',
    city: 'Natal',
    transactionId: '12345678', //max 25 characters
    message: 'trevo: 1, 2, 3',
    // cep: '99999999',
    value: 30.00,
});
export default class OffPage extends Component {
  render() {
    return (
        <Container className='pt-3'>
            <Row>
                <Col xs={12} md={2}></Col>
                <Col xs={12} md={8}>
                    <img width="100%" src="/imgs/trevo-ico32c.png" style={{filter:"grayscale(1) opacity(0.1)"}}/>
                </Col>
                <Col xs={12} md={2}></Col>
            </Row>
        </Container>
    )
  }
}
